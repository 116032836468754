@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */
@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top-color: #4BA163;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  